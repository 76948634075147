import React from 'react'
import tw from 'twin.macro'
import { Img } from 'gatsby-image'
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Hero from '../components/Hero'
import { H1, H2 } from '../components/Headings'

import { formatPhoneNumber } from '../helpers'
import A, { ALink } from '../components/A'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBuilding,
  faChevronRight,
  faClipboard,
  faEnvelope,
  faHome,
  faMapMarkerAlt,
  faMobileAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons'
import { ButtonLink } from '../components/Button'


const Template = ({pageContext: { data }, preview}) => {

    const previewData = (Array.isArray(preview?.revisions) && preview?.revisions?.length)
        ? preview?.revisions[0]
        : preview;

    const pageData = previewData
        ? previewData
        : data;

    const heroImageData = useStaticQuery(graphql`
        {
            hero: allFile(filter: { relativePath: { eq: "branch_hero.jpg" } }) {
                nodes {
                    childImageSharp {
                        fixed(width: 1920, height: 1080) {
                            ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        }
    `);

    const defaultImage = heroImageData.hero?.nodes[0].childImageSharp

    const types = ['residential']
    const { title, content, featuredImage, branchTitle, careerCode, officeAddress, officeCity, officeEmailAddress, officeHours, officePhone, officeState, officeZip, serviceAreas } = pageData;
    
    return (
        <>
            <SEO />
            <Layout>
                <Hero css={['height:350px;']} background={featuredImage?.fixed || defaultImage?.fixed} />
                <Container tw="grid grid-cols-6 col-gap-8">
                    <aside tw="col-span-2 -mt-16 bg-white py-4 px-6">
                        <div tw="mb-8">
                            <div tw="flex items-center justify-between mb-6">
                                <span tw="text-2xl font-bold"><FontAwesomeIcon icon={faMapMarkerAlt} tw="mr-2 text-yellow-600" /> {branchTitle}</span>
                                <div tw="inline text-gray-500">
                                    <FontAwesomeIcon icon={faHome} css={[tw`mr-2`, types.includes('residential') && tw`text-yellow-600`]} />
                                    <FontAwesomeIcon icon={faBuilding} css={[types.includes('commercial') && tw`text-yellow-600`]} />
                                </div>
                            </div>
                            <div tw="md:(grid grid-cols-2 col-gap-4)">
                                <div tw="mb-8">
                                    <p tw="mb-2">
                                        <strong>Branch Office</strong>
                                        <address>
                                            {officeAddress}
                                            <br />{officeCity}, {officeState} {officeZip}
                                        </address>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faMobileAlt} tw="text-gray-500" /> <A href={`tel:${officePhone}`}>{officePhone ? formatPhoneNumber(officePhone) : null}</A>
                                    </p>
                                </div>
                                <div>
                                    <p tw="mb-2">
                                        <strong>Office Hours</strong>
                                        <br />{officeHours}
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faEnvelope} tw="text-gray-500" /> <A href={`mailto:${officeEmailAddress}`}>Email this Office</A>
                                    </p>
                                </div>
                            </div>
                            <ButtonLink href={``} large tw="mb-6">Get a Quote <FontAwesomeIcon icon={faChevronRight} tw="ml-8" /></ButtonLink>
                        </div>
                        <div>
                            <img src="/cookie_logo_grayscale.png" tw="w-40 mb-8"/>
                            <span tw="block text-2xl mb-4">110% Satisfaction Guarantee</span>
                            <p>If you’re not satisfied with our work, we’ll make it right by giving you a free month of service and refunding 10% of your last month’s service fee.</p>
                        </div>
                    </aside>
                    <main tw="col-span-4 py-8">
                        <p tw="mb-4 text-xs"><ALink to="/branches">Branch Locator</ALink> › {title}</p>
                        <H1 tw="text-5xl leading-tight mb-8">Serving {title} Pest Control Since 1928</H1>
                        <div tw="text-gray-600 mb-8" dangerouslySetInnerHTML={{ __html: content || `Cook’s <strong>${title}</strong> branch provides residential pest control and termite protection services for the following cities, towns, and surrounding areas:` }} />
                        <H2 tw="text-2xl">{title}</H2>
                        {serviceAreas ?
                            <ul class="grid grid-cols-3 mb-12">
                                {serviceAreas.map(area => <li>{area}</li>)}
                            </ul>
                        : null}
                        <ButtonLink href={`http://cookspest.applicantstack.com/x/openings?jobtitle=&js_175135=${careerCode}`} target="_blank" large tw="mb-6">Open Positions <FontAwesomeIcon icon={faSignOutAlt} tw="ml-8" /></ButtonLink>
                    </main>
                </Container>
            </Layout>
        </>
    )
}

export default Template
